import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Header, Layout, Root } from '../components';
import { Reg } from '../old/components/admin';
import { TournamentsContextProvider } from '../old/contexts';
import { Page } from './page';

const RegTemplate: React.FC<PageProps<null, { pages: Page[] }>> = ({
  pageContext: { pages },
}) => (
  <>
    <Helmet>
      <title>Yes2Chess - Admin</title>
    </Helmet>
    <Root>
      <Layout>
        <TournamentsContextProvider>
          <Header pages={pages}>
            <Reg />
          </Header>
        </TournamentsContextProvider>
      </Layout>
    </Root>
  </>
);

export default RegTemplate;
