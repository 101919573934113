import { TableBody } from '@material-ui/core';
import { ApiStatus } from '@ssf/y2c-helpers';
import React from 'react';

import { Row } from '..';

export const AdminBody = React.memo<{
  statuses: (ApiStatus & { district: string })[];
  onRefresh(index: number): (status: ApiStatus) => void;
}>(({ statuses, onRefresh }) => (
  <TableBody>
    {statuses.map((status, index) => (
      <Row
        key={status.user}
        index={index + 1}
        status={status}
        onRefresh={onRefresh(index)}
      />
    ))}
  </TableBody>
));
