import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { flatten } from 'flat';
import React from 'react';
import { utils, writeFile } from 'xlsx';

export const Export: React.FC<{ data: unknown[] }> = ({ data }) => {
  const handleClick = () => {
    if (data.length) {
      const sheet = utils.json_to_sheet(data.map((row) => flatten(row)));
      const wb = utils.book_new();
      utils.book_append_sheet(wb, sheet);
      return writeFile(
        wb,
        `y2c-export.${new Date().toLocaleDateString()}.xlsx`,
      );
    }
  };

  return (
    <Button
      onClick={handleClick}
      color="primary"
      variant="contained"
      startIcon={<GetApp />}
    >
      Exportera
    </Button>
  );
};
